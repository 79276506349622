<template>
  <div>
    <div class="pt-5 d-none d-md-block" v-if="getLeilao">
      <div class="container banner">
        <div class="row justify-content-md-center">
          <div class="banner-leilao col-lg-1" v-if="getLeilao">
            <a :href="'/lotes/' + getLeilao.id"
              ><img
                :src="getLeilao.foto.url"
                :title="getLeilao.nome"
                class="foto-leilao"
            /></a>
          </div>
          <div class="banner-info col-lg-7" v-if="getLeilao">
            <div class="info-banner">
              <h6>{{ getLeilao.nome }}</h6>
              <h6 v-if="getLeilao.statusSite === 'aberto'">
                STATUS: ABERTO PARA LANCES
              </h6>
              <h6 v-if="getLeilao.statusSite === 'fechado'">
                STATUS: ENCERRADO
              </h6>
              <h6 v-if="getLeilao.statusSite === 'breve'">STATUS: EM BREVE</h6>
            </div>
            <div class="buttons mt-3" v-if="getLeilao.anexos.length > 0">
              <ul class="lista-botoes">
                <li v-for="anexo in getLeilao.anexos" :key="anexo.ArquivoId">
                  <a
                    class="edital-button"
                    :href="anexo.arquivo.url"
                    target="_blank"
                    >{{ anexo.nome }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="product-auction padding-bottom"
      :class="{
        'padding-leilao': leilaoId != '0',
        'padding-normal': leilaoId == '0',
      }"
    >
      <h6 class="text-center text-muted mb-3 mt-2 d-block d-lg-none">
        <a :href="'/lotes/' + getLeilao.id">{{ getLeilao.nome }}</a>
      </h6>
      <search-bar :leilaoId="leilaoId" />
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-12 mt-2">
            <div class="row mb-2">
              <div class="col-12 col-lg-6">
                <h2 class="title mb-5 mb-lg-0 title-lotes-disponiveis">
                  Pesquisa de Lotes
                </h2>
              </div>
              <div
                class="col-12 col-lg-6 d-flex justify-content-center align-items-start flex-wrap flex-lg-nowrap"
              >
                <div
                  class="combo mr-lg-4 mb-4"
                  @click="classificacaoShow = !classificacaoShow"
                  @focusout="handleFocusOut"
                  tabindex="0"
                >
                  <div class="select-numero-lote">
                    {{ classificacaoText }}
                  </div>
                  <ul :class="{ active: classificacaoShow }">
                    <li
                      @click="classificar('')"
                      :class="{ active: classificacao == '' }"
                    >
                      <input
                        type="radio"
                        name="classificacao"
                        class="form-filtrar"
                        value=""
                        v-model="classificacao"
                      />Número do Lote
                    </li>
                    <li
                      v-for="item in getOrdens"
                      :key="item.titulo"
                      @click="classificar(item.ordem)"
                      :class="{ active: classificacao == item.ordem }"
                    >
                      <input
                        type="radio"
                        name="classificacao"
                        class="form-filtrar"
                        :value="item.ordem"
                        v-model="classificacao"
                      />
                      {{ item.descricao }}
                    </li>
                  </ul>
                </div>
                <div
                  class="combo"
                  v-if="getCategoria"
                  @click="filtrarShow = !filtrarShow"
                  @focusout="handleFocusOut"
                  tabindex="0"
                >
                  <div class="select-numero-lote select-filtrar">
                    FILTRAR
                    <span
                      class="badge badge-warning ml-2 p-1"
                      v-if="qtdFiltro > 0"
                      >{{ qtdFiltro }}</span
                    >
                  </div>
                  <ul :class="{ active: filtrarShow }" class="ul-filtrar">
                    <li
                      v-for="sub in getCategoria.subcategorias"
                      :key="sub.categoriaId"
                      @click="filtrarCategoria(sub.categoriaId)"
                      :class="{ active: categoria == sub.categoriaId }"
                    >
                      <input
                        type="radio"
                        name="filtrar"
                        class="form-filtrar"
                        :value="sub.categoriaId"
                        v-model="categoria"
                      />
                      {{ sub.descricao }}
                    </li>
                    <li @click="filtrarEncerrados()" class="item-encerrados">
                      <input
                        type="checkbox"
                        name="encerrados"
                        class="form-filtrar"
                        v-model="removerEncerrados"
                      />
                      Remover Encerrados
                    </li>
                    <li
                      class="text-center text-danger mt-3"
                      @click="limparFiltro()"
                    >
                      LIMPAR
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row pt-3" v-if="!getLotesLoading">
              <div class="col-12 text-right">
                <p>{{ getTotalLotes }} Lotes Encontrados</p>
              </div>
            </div>
            <div class="row mb-30-none justify-content-center">
              <lote-card-vertical
                v-for="lote in getLotesPorLeilao"
                :key="lote.id"
                :lote="lote"
                classeRaiz="col-lg-6 col-md-12 col-sm-12 justify-content-center"
              />
              <div
                class="col-12 text-center m-5"
                v-if="
                  !getLotesLoading &&
                  getLotesPorLeilao &&
                  getLotesPorLeilao.length == 0
                "
              >
                <h6 class="text-muted">
                  NENHUM LOTE ENCONTRADO COM O FILTRO SELECIONADO
                </h6>
              </div>
              <h4 class="title mb-5 mt-5 verTodos" v-if="getLotesLoading">
                CARREGANDO...
              </h4>
            </div>
            <div class="center d-flex" v-if="getIsNextPageLote">
              <h4
                class="title mb-5 mt-5 verTodos"
                @click="carregarMaisLeiloes"
                v-if="!getLotesLoading"
              >
                CARREGAR MAIS LOTES
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoteCardVertical from '@/components/LoteCardVertical.vue';
import SearchBar from '@/components/SearchBar.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  data() {
    return {
      classificacaoShow: false,
      classificacaoText: 'CLASSIFICAR POR',
      filtrarShow: false,
      filtro: '',
      classificacao: '',
      removerEncerrados: false,
      categoria: 0,
      categorias: [],
      qtdFiltro: 0,
      pageNumber: 0,
      termo: '',
      numeroLote: 0,
      leilaoId: null,
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  components: {
    LoteCardVertical,
    SearchBar,
    // LoteCardLoader,
  },
  watch: {
    pageNumber(value, oldvalue) {
      if (value == oldvalue) return;

      this.getPagina(this.pageNumber);
    },
  },
  computed: {
    ...mapGetters([
      'getOrdens',
      'getLotesPorLeilao',
      'getLotesLoading',
      'getCategorias',
      'getLeilao',
      'getIsNextPageLote',
      'getTotalLotes',
    ]),
    getCategoria() {
      const leilao = this.getLeilao;
      return this.getCategorias.find((x) => x.descricao == leilao.categoria);
    },
  },
  methods: {
    ...mapActions(['setLotesPorLeilao', 'setLeilao']),
    ...mapMutations([
      'SET_LOTES_POR_LEILAO',
      'IS_NEXT_PAGE_LOTE',
      'SET_LEILAO',
    ]),
    carregarMaisLeiloes() {
      this.pageNumber++;
    },
    filtrarLotes() {
      this.SET_LOTES_POR_LEILAO();
      this.filtro = {
        ordem: this.classificacao,
        categorias: this.categorias,
        removerEncerrados: this.removerEncerrados,
      };
      this.atualizaLotes({
        pageNumber: 1,
        pageSize: 6,
        categorias: this.filtro.categorias,
        ordem: this.filtro.ordem || '',
        removerEncerrados: this.filtro.removerEncerrados || false,
        termo: this.termo || '',
        numeroLote: this.numeroLote,
      });
    },
    getPagina(pageNumber) {
      this.atualizaLotes({
        pageNumber: pageNumber,
        pageSize: 6,
        categorias: this.filtro.categorias,
        ordem: this.filtro.ordem || '',
        removerEncerrados: this.filtro.removerEncerrados || false,
        termo: this.termo || '',
        numeroLote: this.numeroLote,
      });
    },
    classificar(ordem) {
      this.classificacao = ordem;
      this.filtrarLotes();
    },
    filtrarCategoria(categoriaId) {
      if (this.categoria == 0) this.qtdFiltro++;
      this.categoria = categoriaId;
      this.categorias = [categoriaId];
      this.filtrarLotes();
    },
    filtrarEncerrados() {
      this.removerEncerrados = !this.removerEncerrados;
      this.removerEncerrados ? this.qtdFiltro++ : this.qtdFiltro--;
      this.filtrarLotes();
    },
    limparFiltro() {
      this.qtdFiltro = 0;
      this.categoria = 0;
      this.categorias = [];
      this.removerEncerrados = false;
      this.filtrarLotes();
    },
    handleFocusOut() {
      this.filtrarShow = false;
      this.classificacaoShow = false;
    },
    atualizaLotes(payload) {
      this.setLotesPorLeilao({
        leilaoId: this.leilaoId || 0,
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize || 6,
        categorias: payload.categorias || '',
        ordem: payload.ordem || '',
        removerEncerrados: payload.removerEncerrados || false,
        termo: payload.termo || '',
        numeroLote: payload.numeroLote || 0,
        status: 'aberto',
      });
    },
  },
  async created() {
    this.pageNumber++;
    this.termo = this.$route.query['t'];
    this.numeroLote = this.$route.query['n'];
    this.leilaoId = this.$route.query['lid'] || '0';

    if (this.leilaoId != '0') {
      this.setLeilao(this.leilaoId);
    } else {
      this.SET_LEILAO();
    }
  },
  destroyed() {
    this.SET_LOTES_POR_LEILAO();
    this.IS_NEXT_PAGE_LOTE();
  },
};
</script>

<style scoped>
.padding-leilao {
  padding-top: 40px;
}

.padding-normal {
  padding-top: 130px;
}

button {
  border-radius: 13px;
}
.combo {
  position: relative;
  width: 100%;
  height: 35px;
}
.select-numero-lote {
  font-weight: 800;
  color: #52606d;
  border-radius: 25px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
  height: 35px;
  border: none;
  background: #f7f7f7;
  background-image: url(/assets/images/chevrons-down.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 6px;
  width: 100%;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 9;
  font-size: 0.9em;
}

.combo ul {
  position: absolute;
  top: 0;
  padding: 45px 0 10px;
  border-radius: 20px;
  background-color: #fff;
  z-index: 8;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
  width: 100%;
  text-align: left;
  display: none;
}

.combo ul.active {
  display: block;
  -webkit-animation: fadeIn 500ms;
  animation: fadeIn 500ms;
}

.combo ul li {
  color: #52606d;
  padding: 2px 15px;
  display: block;
  font-size: 16px;
  font-weight: 500;
}

.combo ul li:hover,
.combo ul li.active {
  background-color: #d9d9d973;
  cursor: pointer;
}

.combo label {
  margin: 0;
  padding: 0;
}

.select-filtrar {
  z-index: 7 !important;
}

.ul-filtrar {
  z-index: 6 !important;
}

.item-encerrados {
  margin-top: 20px;
  padding: 15px 0 15px 15px !important;
  border-top: 1px solid #ddd;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.verTodos {
  color: #0b184a;
  cursor: pointer;
}

.form-filtrar {
  display: inline;
  width: 12px;
  height: 12px;
  margin-right: 15px;
}

.banner-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  color: v-bind(colorConstrast) !important;
}

.banner-leilao a {
  height: 100%;
  width: 100%;
}

.banner-leilao {
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  height: 94px;
}
.banner {
  margin-top: 60px !important;
  background-color: v-bind(colorPrimary);
  border-radius: 20px;
  padding: 20px;
}

.foto-leilao {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: contain;
}
.product-details {
  margin-top: 20px;
}

.info-banner h6 {
  color: v-bind(colorConstrast) !important;
}

.info-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
}

.lista-botoes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.edital-button {
  padding: 0 10px;
  border-radius: 30px;
  background-color: v-bind(colorSecondary) !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
@media (max-width: 1199px) {
  .title-lotes-disponiveis {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .product-auction {
    padding-top: 180px !important;
  }
}
</style>
